import React, { Component, Fragment } from 'react'
import {
  Card,
  CardContent,
  Typography,
  withStyles,
  Grid,
  TextField,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import numeral from 'numeral'

import paypalCalculatorStyles from './paypalCalculator_styles.jsx'

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      thousandSeparator
      prefix="$ "
    />
  )
}

class PaypalCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paypal: 0,
      amount: null,
      fee: null,
      paypalprcfee: 0.054,
      paypalusdfee: 0.3,
      totalReceived: 0,
      totalBolivar: 0,
      totalBoliver2: 0,
      totalSend: 0,
      fee2: null,
    }
  }

  handleAmountChange = e => {
    let amount
    let fee
    let paypalusdfee
    if (e.target.value === '') {
      amount = 0
      fee = 0
      paypalusdfee = 0
    } else {
      paypalusdfee = 0.3
      amount = e.target.value / 1.0
      fee =
        Math.round((amount * this.state.paypalprcfee + paypalusdfee) * 100) /
        100
    }

    let totalReceived = Math.round((amount - fee) * 100) / 100
    let totalBolivar = Math.round(this.state.paypal * totalReceived * 100) / 100
    let totalSend =
      Math.round(
        ((amount + paypalusdfee) / (1 - this.state.paypalprcfee)) * 100
      ) / 100
    let totalBolivar2 = Math.round(this.state.paypal * amount * 100) / 100
    let fee2 = totalSend - amount
    this.setState({
      fee,
      amount,
      totalReceived,
      totalBolivar,
      totalSend,
      totalBolivar2,
      fee2,
    })
  }

  render() {
    const { classes, amount } = this.props
    return (
      <Fragment>
        <Card className={classes.paypalCard}>
          <CardContent>
            <Typography variant="h5" className={classes.cartTitle}>
              Calcula tu comisión{' '}
              <strong style={{ textDecoration: 'underline' }}>Paypal</strong>
            </Typography>
            <Grid container spacing={16}>
              <Grid item>
                <TextField
                  value={amount}
                  id="adornment-amount"
                  placeholder="monto en USD"
                  onChange={this.handleAmountChange}
                  autoFocus
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    className: classes.textfield,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.percentage}>
                  5,4% + 0,30
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.comisionWrapper}>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Recibirá
                </Typography>
                <Typography className={classes.amount}>
                  {numeral(this.state.totalReceived).format('$0,0.00')}
                </Typography>
                <Typography className={classes.amountDetails}>
                  si se envían{' '}
                  <strong>
                    {numeral(this.state.amount).format('$0,0.00')}
                  </strong>
                </Typography>
                <Typography className={classes.amountDetails}>
                  Comisión{' '}
                  <strong>{numeral(this.state.fee).format('$0,0.00')}</strong>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Debe Pedir
                </Typography>
                <Typography className={classes.amount}>
                  {numeral(this.state.totalSend).format('$0,0.00')}
                </Typography>
                <Typography className={classes.amountDetails}>
                  para recibir{' '}
                  <strong>
                    {numeral(this.state.amount).format('$0,0.00')}
                  </strong>
                </Typography>
                <Typography className={classes.amountDetails}>
                  Comisión{' '}
                  <strong>{numeral(this.state.fee2).format('$0,0.00')}</strong>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    )
  }
}

export default withStyles(paypalCalculatorStyles)(PaypalCalculator)
