import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import {
  AppBar,
  Toolbar,
  MuiThemeProvider,
  withStyles,
  CssBaseline,
  Typography,
  Grid,
} from '@material-ui/core'

import {} from '../styles/main.css'
import { mk_theme } from '../styles/mkStyles.jsx'

class layout extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            mkImage: file(relativePath: { eq: "logo.png" }) {
              childImageSharp {
                fluid(maxWidth: 41) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            SiteTitleQuery: site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => {
          const { children, classes } = this.props
          return (
            <MuiThemeProvider theme={mk_theme}>
              <Helmet title={data.SiteTitleQuery.siteMetadata.title} />
              <CssBaseline />
              <AppBar position="static" color="primary" elevation={1} className={classes.topBar}>
                <Toolbar>
                  <Grid
                    container
                    spacing={8}
                    style={{ maxWidth: '1000px', margin: 'auto' }}
                  >
                    <Grid item>
                      <Img
                        className={classes.carbon_icon}
                        fluid={data.mkImage.childImageSharp.fluid}
                      />
                    </Grid>
                    <Grid item style={{ position: 'relative' }}>
                      <Typography className={classes.logoText}>
                        mykurrencies
                      </Typography>
                      <Typography className={classes.slogan}>
                        Tus Divisas
                      </Typography>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
              <Grid
                container
                className={classes.main_wrapper}
              >
                <Grid item>{children}</Grid>
              </Grid>
            </MuiThemeProvider>
          )
        }}
      />
    )
  }
}

export default withStyles(mk_theme)(layout)
