import React, { Component } from 'react'
import Layout from '../components/layout.jsx'

import { Grid, Typography, withStyles } from '@material-ui/core'

import PaypalCalculator from '../components/PaypalCalculator/PaypalCalculator.jsx'
import Indicadores from '../components/Indicadores/Indicadores.jsx'
//import Indilinechart from '../components/charts/lineChart.jsx'

const indexStyles = ({ breakpoints }) => ({
  nota: {
    marginTop: '50px',
  },
})
class index extends Component {
  render() {
    const { classes } = this.props
    return (
      <Layout>
        <Grid container spacing={8}>
          <Grid item>
            <PaypalCalculator />
          </Grid>
          <Grid item>
            <Indicadores />
          </Grid>
        </Grid>
        <Grid>
          <Grid item className={classes.nota}>
            <Typography variant="h3">Nota</Typography>
            <Typography variant="body1">
              <strong>myKurrencies</strong> no especula con los precios de las
              divisas, solo obtenemos la informacion de sus fuentes y la
              mostramos en un solo lugar, el promedio se calcula en base
              al precio original.
            </Typography>
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withStyles(indexStyles)(index)
