import React, { Component, Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import {
  Card,
  CardContent,
  CardActions,
  withStyles,
  Grid,
  Typography,
} from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es'

import IndicadorDetail from '../indicadorDetail/indicadorDetail.jsx'
import styleIndicadores from './indicadores_styles.jsx'
import _ from 'lodash'
import numeral from 'numeral'

class Indicadores extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      dataIndicadores: {},
    }
  }

  randomeUID = () => {
    return _.random(5000000, true)
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            upImage: file(relativePath: { eq: "up.png" }) {
              childImageSharp {
                fluid(maxWidth: 41) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            downImage: file(relativePath: { eq: "down.png" }) {
              childImageSharp {
                fluid(maxWidth: 41) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            indicadoresData: allIndicadoresDiaJson {
              edges {
                node {
                  name
                  rate
                }
              }
            }
          }
        `}
        render={data => {
          const { classes } = this.props
          moment.locale('es')
          let total = 0
          return (
            <Fragment>
              <Card className={classes.root}>
                <CardActions>
                  <Typography
                    variant="caption"
                    className={classes.indicadoresTitle}
                  >
                    Valor representado en <strong>Bolivares Soberanos</strong>{' '}
                    <strong>por USD</strong>
                  </Typography>
                </CardActions>
                <CardContent>
                  <Grid container spacing={40}>
                    {_.map(data.indicadoresData.edges, indi => {
                      total = total + indi.node.rate
                      return (
                        <Grid
                          key={this.randomeUID()}
                          item
                          className={classes.indicadorGridItem}
                        >
                          <IndicadorDetail
                            percentageImg={data.upImage}
                            indicador={indi.node}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </CardContent>
                <CardActions className={classes.promedioContainer}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography className={classes.indicadoresDate}>
                        {moment().format('MMMM D, YYYY, h:mm:ss a')}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.promedioText}>
                      <Typography>
                        Promedio{' '}
                        <span className={classes.indicadoresTotal}>
                          {`BsS. ${numeral(
                            total / _.size(data.indicadoresData.edges)
                          ).format('0,0.00')} `}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Fragment>
          )
        }}
      />
    )
  }
}

export default withStyles(styleIndicadores)(Indicadores)
