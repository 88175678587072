const styleIndicadores = ({ breakpoints }) => ({
  root: {
    height: '260px',
    [breakpoints.down('sm')]: {
      width: 345,
      height: 'auto',
    },
    [breakpoints.up('md')]: {
      width: 566,
    },
    [breakpoints.up('lg')]: {
      width: 566,
    },
  },
  /*  indicadorTitulo: {
    fontSize: '18px',
    fontWeight: '700',
  }, */
  indicadorMonto: {
    fontWeight: '700',
  },
  indicadorPercentage: {
    fontWeight: '700',
  },
  percentageIcon: {
    width: '10.3px',
    top: '8px',
  },
  indicadoresDate: {
    //fontSize: '13px',
    top: '8px',
    position: 'relative',
    [breakpoints.up('md')]: {
      fontSize: '14px',
    },
    [breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  indicadoresTotal: {
    fontSize: '20px',
    fontWeight: '700',
  },
  indicadorGridItem: {
    /*  width: '175px',
    [breakpoints.down('sm')]: {
      width: '175px',
    }, */
  },
  promedioContainer: {
    borderTop: '1px solid #f0f0f0',
    padding: '5px 12px',
    [breakpoints.down('sm')]: {
      padding: '10px 4px 25px',
    },
  },
  promedioText: {
    textAlign: 'right',
    marginTop: '0px',
    [breakpoints.down('sm')]: {
      marginTop: '10px',
    },
  },
  loading: {
    textAlign: 'center',
    marginTop: '8%',
    [breakpoints.down('sm')]: {
      marginTop: '0%',
    },
  },
  indicadoresTitle: {
    margin: '0 auto',
    [breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
})

export default styleIndicadores
