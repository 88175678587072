const paypalCalculatorStyles = ({ palette, breakpoints }) => ({
  paypalCard: {
    background: palette.secondary.main,
    height: '100%',
    [breakpoints.down('sm')]: {
      width: 345,
    },
    [breakpoints.up('md')]: {
      width: 345,
    },
    [breakpoints.up('lg')]: {
      width: 380,
    },
  },
  cartTitle: {
    color: palette.text.secondary,
    marginBottom: '15px',
    fontSize: '25px',
  },
  percentage: {
    fontSize: '20px',
    fontWeight: '700',
    color: palette.common.white,
  },
  textfield: {
    color: palette.common.white,
    fontSize: '17px',
    [breakpoints.down('sm')]: {
      width: '170px',
    },
    [breakpoints.up('md')]: {
      width: '170px',
    },
    [breakpoints.up('lg')]: {
      width: '200px',
    },
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: palette.common.white,
    marginBottom: '10px',
  },
  comisionWrapper: {
    marginTop: '15px',
  },
  amount: {
    fontSize: '25px',
    fontWeight: '700',
    color: palette.common.white,
  },
  amountDetails: {
    color: palette.common.white,
  },
})

export default paypalCalculatorStyles
