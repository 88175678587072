import { createMuiTheme } from '@material-ui/core/styles'

export const mk_theme = createMuiTheme({
  /*  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: [360, 768, 992, 1200, 1440],
  }, */
  '@global': {
    body: {},
  },
  topBar: {
    marginBottom: '25px',
  },
  main_wrapper: {
    maxWidth: '1000px',
    margin: 'auto',
    padding: '0 16px',
  },
  carbon_icon: {
    width: '43px',
    maxWidth: '43px'
  },
  logoText: {
    fontSize: '25px',
    fontWeight: 700,
  },
  slogan: {
    top: '28px',
    position: 'absolute',
    fontSize: '18px',
    left: '65px',
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 450,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#ffffff', default: '#F6F6FA' },
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#624FFF',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#3A3D4C',
      secondary: '#B0A7FF',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Nunito',
    fontSize: '15px',
  },
  overrides: {
    TextField: {
      color: 'blue',
    },
  },
})
