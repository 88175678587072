import React, { Component, Fragment } from 'react'
//import Img from 'gatsby-image'
import { Typography, withStyles } from '@material-ui/core'
import numeral from 'numeral'

const styleIndicador = ({ breakpoints }) => ({
  indicadorTitulo: {
    fontSize: '18px',
    fontWeight: '700',
    textTransform: 'capitalize',
    color: '#717171',
  },
  indicadorMonto: {
    fontWeight: '700',
  },
  indicadorPercentage: {
    fontWeight: '700',
  },
  percentageIcon: {
    width: '10.3px',
    top: '8px',
  },
})

class indicadorDetail extends Component {
  render() {
    const { classes, indicador } = this.props
    return (
      <Fragment>
        <Typography className={classes.indicadorTitulo}>
          {indicador.name}
        </Typography>
        <Typography className={classes.indicadorMonto}>
          {`BsS. ${numeral(indicador.rate).format('0,0.00')} `}
        </Typography>
        {/* <Grid container spacing={8}>
          <Grid item>
            <Img
              className={classes.percentageIcon}
              fluid={percentageImg.childImageSharp.fluid}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.indicadorPercentage}>
              12.3%
            </Typography>{' '}
          </Grid>
        </Grid> */}
      </Fragment>
    )
  }
}

export default withStyles(styleIndicador)(indicadorDetail)
